<template>
  <v-container
    id="dataexport"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <!-- Dashboard Block Info' toolbar -->
    <v-toolbar
      style="z-index: 1"
      color="elevation-0"
      dense
    >
      <v-toolbar-title class="text-h2 grey--text text--darken-0 pr-8">
        {{ $store.state.main.af.title }} - {{ $t('monitoring.tables') }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Open and close InfoCard -->
      <v-btn
        v-if="myRole() === 'manager'"
        color="warning"
        fab
        dark
        @click="helptoolbar = !helptoolbar"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Add Monitoring Tables block -->
    <v-card
      v-if="!helptoolbar"
      class="mt-0"
      color="elevation-0"
    >
      <v-row class="px-8 pt-4">
        <!-- List of dataset e.g. Sections -->
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <v-select
            :items="dataSet"
            outlined
            dense
            single-line
            item-text="label[0]"
            item-value="table[0]"
            prepend-inner-icon="mdi-table"
            :label="$t('analysis.select-dataset')"
            @change="dataSetSelected"
          />
        </v-col>
        <!-- List of question e.g. variables for selected dataset -->
        <v-col
          cols="12"
          md="8"
          lg="9"
        >
          <v-autocomplete
            v-model="myVariables"
            outlined
            dense
            :items="questions"
            :item-text="questionLabels.type"
            item-value="id"
            :prepend-icon="questionLabels.icon"
            :label="$t('analysis.select-vars')"
            multiple
            @input="questionSelected"
            @click:prepend="changeLabel"
          >
            <template v-slot:append-outer>
              <v-checkbox
                v-model="pTable"
                class="py-0 my-0"
                append-icon="mdi-delete"
                @click="switchType"
              >
                <template v-slot:append>
                  <v-icon
                    v-if="!pTable"
                    class="py-0 my-0"
                  >
                    mdi-chart-bar
                  </v-icon>
                  <v-icon
                    v-else
                    class="py-0 my-0"
                  >
                    mdi-table
                  </v-icon>
                </template>
              </v-checkbox>
            </template>
          </v-autocomplete>
        </v-col>
        <!-- Pivot table -->
        <span class="main">
          <div class="table-responsive">
            <vue-pivottable-ui
              v-if="istable"
              v-model="mTable"
              :data="pivotData"
              :aggregator-name="aggregatorName"
              :renderers="renderers"
              :renderer-name="rendererName"
              :rows="rows"
              :cols="cols"
              :vals="vals"
              :value-filter="valueFilter"
              :disabled-from-drag-drop="disabledFromDragDrop"
              :sortonly-from-drag-drop="sortonlyFromDragDrop"
              :hidden-from-drag-drop="hiddenFromDragDrop"
            >
              <!-- Slot ColGroup -->
              <template v-slot:colGroup>
                <colgroup>
                  <col :width="300">
                  <col>
                </colgroup>
              </template>
              <template v-slot:pvtAttr="{ name }">
                <v-icon>mdi-filter</v-icon>
                {{ name[0].toUpperCase() + name.substring(1) }}
              </template>
            </vue-pivottable-ui>
          </div>
          <!-- <div class="table-responsive">
            <vue-pivottable
              :data="pivotData"
              :aggregator-name="mTable.aggregatorName"
              :col-order="mTable.colOrder"
              :cols="mTable.cols"
              :renderer-name="mTable.rendererName"
              :rows="mTable.rows"
              :row-order="mTable.rowOrder"
              :vals="mTable.vals"
              :value-filter="valueFilter"
            />
          </div> -->
        </span>
      </v-row>
      <validation-observer v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(mtSave)">
          <v-card-actions
            v-if="myRole() === 'manager' & istable & pTable"
            class="pb-4 px-8"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('mpref.mt-title')"
              rules="required"
            >
              <v-text-field
                v-model="mtTitle"
                outlined
                dense
                hide-details
                :error-messages="errors"
                :label="$t('mpref.mt-title')"
              />
              <v-spacer />
            </validation-provider>
            <v-spacer />
            <v-btn
              color="warning"
              dark
              @click="clearPTable()"
            >
              {{ $t('common.clear') }}
            </v-btn>
            <v-btn
              color="success"
              dark
              type="submit"
            >
              {{ $t('common.save') }}
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
      <v-card-actions>
        <v-row class="pb-4 px-8">
          <v-spacer />
          <!-- Keep closed -->
          <v-checkbox
            v-model="dBInfo"
            :label="$t('dashboard.keep-closed')"
            class="py-0 my-0"
            hide-details
          />
        </v-row>
      </v-card-actions>
    </v-card>

    <monitoring-table-list ref="mtTable" />

  </v-container>
</template>

<script>
  import { VuePivottableUi, Renderer } from 'vue-pivottable'
  import 'vue-pivottable/dist/vue-pivottable.css'
  import userAccess from '@/mixins/user-access'
  import dashboard from '@/mixins/dashboard'
  import MonitoringTableList from './components/MonitorTableList.vue'

  export default {
    name: 'App',
    components: {
      VuePivottableUi,
      // VuePivottable,
      MonitoringTableList,
    },

    mixins: [
      userAccess,
      dashboard,
    ],

    data () {
      return {
        helptoolbar: true,
        pivotReady: false,
        pTable: true,

        pivotData: [],
        aggregatorName: 'Count',
        rendererName: 'Table',
        rows: [],
        cols: [],
        vals: [],
        valueFilter: {},

        disabledFromDragDrop: [],
        hiddenFromDragDrop: [],
        sortonlyFromDragDrop: [],
        // exclusions: '',
        // inclusions: '',

        // Variables in selected table
        selectedTable: [],
        questions: [],
        questionLabels: {
          icon: 'mdi-unfold-more-vertical',
          type: 'id',
        },
        myVariables: [],
        mtTitle: '',
        mTable: {},
      }
    },

    computed: {

      dataSet () {
        return this.$store.state.ocpu.tableListRules
      },

      // Keep dBlock info open or closed
      dBInfo: {
        get () {
          return this.$store.state.main.dsb.fsettings.dmTableInfo
        },
        set (value) {
          this.$store.commit('main/setfSetting', { el: 'dmTableInfo', val: value })
          // console.log(this.$store.state.main.dsb.fsettings)
          this.dBlockSave('fsettings', this.$store.state.main.dsb.fsettings)
        },
      },

      istable () {
        return this.pivotData.length > 0
      },

      renderers () {
        if (this.pTable) {
          const TableRenderer = Renderer.TableRenderer
          return (() => ({
            Table: TableRenderer.Table,
            'Table Heatmap': TableRenderer['Table Heatmap'],
            'Table Col Heatmap': TableRenderer['Table Col Heatmap'],
            'Table Row Heatmap': TableRenderer['Table Row Heatmap'],
          })
          )()
        } else {
          const PlotlyRenderer = Renderer.PlotlyRenderer
          return (() => ({
            'Grouped Column Chart': PlotlyRenderer['Grouped Column Chart'],
            'Stacked Column Chart': PlotlyRenderer['Stacked Column Chart'],
            'Grouped Bar Chart': PlotlyRenderer['Grouped Bar Chart'],
            'Stacked Bar Chart': PlotlyRenderer['Stacked Bar Chart'],
            'Line Chart': PlotlyRenderer['Line Chart'],
          })
          )()
        }
      },
    },

    mounted () {
      const main = this.$store.state.main
      // If this a first visit get dashboard blocks from ocpu
      if (main.dsb.calls === false) {
        // Get Table list from ocpu
        // this.getTableList()

        // Get block info from ocpu dBlocks - dashboarBlocks; dDataset - dashboardDataset; fsettings - Form settings
        // const blocks = ['fsettings', 'dBlocks']
        // blocks.forEach(type => {
        //   // Using function in mixin dashboard.js
        //   this.dBlockGet(type)
        // })
      }
      this.helptoolbar = this.$store.state.main.dsb.fsettings.dmTableInfo
    },

    methods: {

      dataSetSelected (dataSet) {
        this.pivotData = []
        this.myVariables = null
        let myData = this.$store.state.ocpu.tableListRules
        myData = myData.filter(function (item) {
          return item.table.includes(dataSet)
        })
        // console.log(myData)
        this.selectedTable = myData
        this.questions = myData[0].column
      },

      changeLabel () {
        if (this.questionLabels.type === 'id') {
          this.questionLabels.type = 'label'
          this.questionLabels.icon = 'mdi-unfold-less-vertical'
        } else {
          this.questionLabels.type = 'id'
          this.questionLabels.icon = 'mdi-unfold-more-vertical'
        }
      },

      questionSelected (myQuestion) {
        // console.log(this.myVariables)
        // console.log(myQuestion)
        const columns = this.myVariables
        const prop = {
          orgid: this.$store.state.main.orgid,
          prjid: this.$store.state.main.ap.prjid,
          formid: this.$store.state.main.afid,
          tblname: this.selectedTable[0].table[0],
          columns: columns,
        }
        // console.log(prop)
        this.$store.dispatch('ocpu/getOcvars', prop).then(response => {
          // console.log(response.data)
          this.pivotData = response.data
        })
      },

      switchType () {
        // console.log(this.pTable)
        this.pivotData = []
        this.myVariables = null
      },

      async mtSave () {
        // console.log(this.mTable)
        // clone object
        const configCopy = JSON.parse(JSON.stringify(this.mTable))
        // delete items from object
        delete configCopy.aggregators
        delete configCopy.renderers
        delete configCopy.data
        delete configCopy.derivedAttributes
        delete configCopy.disabledFromDragDrop
        delete configCopy.hiddenAttributes
        delete configCopy.hiddenFromAggregators
        delete configCopy.hiddenFromDragDrop
        delete configCopy.menuLimit
        delete configCopy.sorters
        delete configCopy.sortonlyFromDragDrop
        delete configCopy.unusedAttrs

        const settings = {}
        // generate ID using current date and time in milliseconds
        settings.id = (new Date()).getTime()
        settings.type = 'mttable'
        settings.name = this.mtTitle
        settings.tblname = this.selectedTable[0].table
        settings.vars = configCopy.cols.concat(configCopy.rows)
        settings.details = configCopy
        // console.log(this.mTable)
        // console.log(JSON.parse(JSON.stringify(settings)))

        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const savemt = () => {
          return new Promise((resolve, reject) => {
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocmtoolsave/json`, {
                mtdetails: JSON.stringify(settings),
                orgid: this.$store.state.main.orgid,
                prjid: this.$store.state.main.ap.prjid,
                formid: this.$store.state.main.afid,
              })
              .then(response => {
                // console.log(response)
                if (response.status === 201) {
                  // this.getMtoolsList()
                  resolve(response)
                }
              })
              .catch(error => {
                // console.log(error)
                reject(error)
              })
          })
        }
        await savemt()
        this.$refs.mtTable.getmttable()
        this.$refs.mtTable.getmtvars()

        // for (let i = 0; i < configCopy.length; i++) {
        //   settings.variables[i].value = this.selectedVar[i]
        // }

        // const settings = JSON.stringify(this.mttype)
        // this.saveMTools(settings)
        // this.dialogMt = false
        // this.dialogBlocks = false
        // this.mttype = ''
        // this.section = ''
        // this.mtTitle = ''
        // this.selectedVar = []

        // col-order="mTable.colOrder"
        // cols="mTable.cols"
        // aggregator-name="mTable.aggregatorName"
        // renderer-name="mTable.rendererName"
        // row-order="mTable.rowOrder"
        // rows="mTable.rows"
        // vals="mTable.vals"

        // console.log(this.selectedTable[0].table[0])
        // console.log(configCopy)
      },

      clearPTable () {
        this.pivotData = []
        this.myVariables = null
        this.aggregatorName = 'Count'
        this.rendererName = 'Table'
        this.rows = []
        this.cols = []
        this.vals = []
        this.valueFilter = {}
        // console.log(this.mTable)
        // console.log(this.mTable.rendererName)
      },

    },
  }
</script>

<style>
  .main {
    max-width: 98%;
    margin: 0px auto 20px;
  }
  h1 {
    text-align: center;
  }
  .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  }
  /* .plot-container .plotty{
    width: 90% !important;
  } */
  /* .main-svg {
    width: 100% !important;
  } */
  pre {
    text-align: left;
    background-color: #f8f8f8;
    padding: 1.2em 1.4em;
    line-height: 1.5em;
    margin: 60px 0 0;
    overflow: auto;
  }
  code {
    padding: 0;
    margin: 0;
  }
</style>
