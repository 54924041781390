<template>
  <!-- Pivot Table PART -->
  <v-row class="px-3">
    <template v-for="ptable in $store.state.main.mtools.pivotTable">
      <v-card
        :key="ptable.id[0]"
        outlined
        width="100%"
      >
        <v-toolbar
          flat
          color="grey lighten-5"
          dense
        >
          <!-- Drag button -->
          <v-btn
            :color="color"
            class="mr-4"
            fab
            dark
          >
            <v-icon
              class="handle"
            >
              mdi-table
            </v-icon>
          </v-btn>

          <v-toolbar-title :class="`text-h3 ${color}--text text--darken-1`">
            {{ ptable.name[0] }}
          </v-toolbar-title>
          <v-spacer />

          <!-- Delete button -->
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                color="error"
                v-on="on"
                @click="deletemttbl(ptable.id[0])"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('common.remove') }}</span>
          </v-tooltip>
        </v-toolbar>
        <div
          v-if="!mTables"
          class="loading"
        >
          <v-progress-linear
            indeterminate
            :color="color"
          />
          ...{{ $t('tooltip.loading') }}
        </div>

        <div class="table-responsive">
          <vue-pivottable
            v-if="mTables"
            class="d-flex justify-center mt-2"
            :data="monitoringData[ptable.tblname]"
            :aggregator-name="ptable.details.aggregatorName[0]"
            :renderer-name="ptable.details.rendererName[0]"
            :cols="ptable.details.cols"
            :col-order="ptable.details.colOrder[0]"
            :rows="ptable.details.rows"
            :row-order="ptable.details.rowOrder[0]"
            :vals="ptable.details.vals"
            :value-filter="ptable.details.valueFilter"
          />
        </div>
        <v-card-actions
          v-if="mTables"
          class="pb-0 px-8"
        >
          {{ filteredVar(ptable.details.valueFilter) }}
        </v-card-actions>
      </v-card>
    </template>
  </v-row>
</template>

<script>
  import { VuePivottable } from 'vue-pivottable'
  import 'vue-pivottable/dist/vue-pivottable.css'

  export default {
    name: 'MonitoringTables',
    components: {
      VuePivottable,
    },

    data () {
      return {
        monitoringData: {},
        mTables: false,
        color: 'secondary',
        loading: false,
      }
    },

    computed: {},

    created () {
      this.getmttable()
    },

    mounted () {},

    methods: {

      async getmttable () {
        const mttbl = () => {
          const ocpuPack = this.$store.state.ocpu.ocpuPack
          const main = this.$store.state.main
          const prop = {
            orgid: main.orgid,
            prjid: main.ap.prjid,
            formid: main.afid,
            type: 'mttable',
          }
          // console.log(prop)
          // console.log(main.mtoolList.length)
          if (main.mtoolList.length === 0) {
            return new Promise((resolve, reject) => {
              this.$httpOcpu
                .post(`/${ocpuPack}/R/ocmtoolget/json`, prop)
                .then(response => {
                  // console.log(response)
                  if (response.status === 201) {
                    // console.log(response)
                    this.$store.commit('main/setMtool', { type: 'pivotTable', data: response.data })
                    resolve(response)
                  }
                })
                .catch(error => {
                  // console.log(error)
                  reject(error)
                })
            })
          }
        }
        await mttbl()
        this.getmtvars()
      },

      async getmtvars () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // function to get variable values
        const getocvars = (prop) => {
          // console.log(prop)
          return new Promise((resolve, reject) => {
            // console.log(prop)
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocvariables/json`, prop)
              .then(response => {
                // console.log(response)
                // console.log(response.data.length)

                if (response.data.length > 0) {
                  const obj = {}
                  obj[prop.tblname] = response.data
                  // console.log(response)
                  this.monitoringData = Object.assign({}, this.monitoringData, obj)
                  // console.log(this.monitoringData)
                  this.mTables = true
                  resolve(response.data[0])
                }
              })
          })
        }

        const main = this.$store.state.main
        const prop = {
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
        }

        // https://flaviocopes.com/how-to-get-unique-properties-of-object-in-array/
        // Get Unique value
        const tblnames = [...new Set(main.mtools.pivotTable.map(tbl => tbl.tblname[0]))]
        // console.log(tblnames)
        // loop tables/section to get vars from ocpu
        for (let i = 0; i < tblnames.length; i++) {
          prop.tblname = tblnames[i]
          // console.log(prop.tblname)
          await getocvars(prop)
        }
      },

      async deletemttbl (prop) {
        // console.log(prop)
        const mttbldel = () => {
          const ocpuPack = this.$store.state.ocpu.ocpuPack
          const main = this.$store.state.main
          return new Promise((resolve, reject) => {
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocmtdel/json`, {
                mtool: { id: prop, type: 'mttable' },
                orgid: main.orgid,
                prjid: main.ap.prjid,
                formid: main.afid,
              })
              .then(response => {
                // console.log(response)
                if (response.status === 201) {
                  this.getmttable()
                  resolve(response)
                }
              })
              .catch(error => {
                // console.log(error)
                reject(error)
              })
          })
        }
        await mttbldel()
        this.getmtvars()
      },

      filteredVar (valueFilter) {
        // https://stackoverflow.com/a/14810722
        Object.filter = (obj, predicate) =>
          Object.fromEntries(Object.entries(obj).filter(predicate))
        const aha = Object.filter(valueFilter, ([name, score]) => !Array.isArray(score))

        if (Object.keys(aha).length !== 0) {
          let filtered = ''
          for (let index = 0; index < Object.keys(aha).length; index++) {
            // console.log(Object.keys(aha).length)
            filtered = `${filtered} Filtered out from ${Object.keys(aha)[index]}: `
            Object.keys(Object.values(aha)[index]).forEach(function (item, index) {
              filtered = `${filtered} ${item};`
            })
          }

          return filtered
        }
      },

    },
  }
</script>

<style>
  /* Make table scrolling if wider */
  .table-responsive {
    padding: 2px;
    display: block;
    width: 100%;
    overflow-x: auto;
  }
</style>
